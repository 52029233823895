<template>
  <v-app>
    <component :class="{ test: isTest }" :is="getThemeMode.layout" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      isTest: false,
      layoutName: "",
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  methods: {
    ...mapActions(["changeThemeLayout"]),
  },
};
</script>
